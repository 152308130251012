export const DATE_FORMAT_COMMON = 'dd/MM/yyyy';
export const DATE_FORMAT_API_PARAM = 'yyyy-MM-dd';
export const DATE_TIME_PIPE_FORMAT = 'dd/MM/yyyy h:mm:ss';
export const TIME_24_FORMAT = 'HH:mm';
export const DATE_TIME_ISO_PAYLOAD = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_TIME_UI = 'DD/MM/YYYY HH:mm';

export const DATE_UI = 'DD/MM/YYYY';
export const DATE_YEAR_MONTH_UI = 'MM/YYYY';
export const DATE_MOMENT_ISO = 'YYYY-MM-DD';
export const DATE_TIME_MOMENT_ISO = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const DATE_ONLY_MOMENT_YEAR_MONTH = 'YYYY-MM';
export const DATE_MOMENT_HIJRI_SLASH = 'iDD/iMM/iYYYY';
export const DATE_MOMENT_HIJRI_YEAR_MONTH = 'iMM/iYYYY';
export const DATE_MOMENT_HIJRI_DASH = 'iYYYY-iMM-iDD';
export const DATE_MOMENT_HIJRI_YEAR_MONTH_DASH = 'iYYYY-iMM';
